// Cabb-in configurator

import html2canvas from 'html2canvas';
/* global ajax_object */

$(document).on('ready', function () {
  // Base color option selector
  $(document).on('click', '.js-base-option', function (e) {
    e.preventDefault();
    let $this = $(this),
      $options = $this.closest('.cabb-options'),
      $product = $this.closest('.product'),
      $image = $product.find('.js-cabb-image'),
      $overlay = $image.closest('.ajax-overlay');

    $overlay.toggleClass('option-selected', !$this.hasClass('selected'));

    if ($this.hasClass('selected')) {
      $this.removeClass('selected');
    } else {
      $options.find('.js-base-option').removeClass('selected');
      $this.addClass('selected');
    }

    if ($this.data('image')) {
      $.ajax({
        url: ajax_object.ajax_url, // AJAX handler
        data: {
          action: 'get_image_tag',
          image_id: $this.data('image'),
        },
        type: 'POST',
        beforeSend: function () {
          $overlay.addClass('ajax-overlay--active');
        },
        success: function (response) {
          $image.html(response.html);
          setTimeout(() => {
            $overlay.removeClass('ajax-overlay--active');
          }, 100);
        },
        error: function (xhr, ajaxOptions, thrownError) {
          // For debugging, console log any errors. In production, do something different.
          console.log(xhr.status);
          console.log(thrownError);
        },
      });
    }
  });

  //  - - - - - - Configurator - - - - - -
  let activeOption = {};
  $(document).on('click', '.js-config-option', function (e) {
    e.preventDefault();
    let $this = $(this),
      $section = $this.closest('.s-product-configurator'),
      $container = $section.find('.js-config-container'),
      optionId = $this.data('image'),
      title = $this.data('title'),
      $options = $this.closest('.cabb-options'),
      group = $options.data('group'),
      $images = $section.find('.js-config-layer');

    $container.addClass('ajax-overlay--active');

    if ($this.hasClass('selected')) {
      $this.removeClass('selected');
      delete activeOption[group];
    } else {
      $options.find('.js-config-option').removeClass('selected');
      $this.addClass('selected');
      activeOption[group] = {
        image: optionId,
        title: title,
      };
    }

    // Hide images
    $images.each(function (i, el) {
      if ($(el).data('group') === group) {
        $(el).fadeOut();
      }
    });

    // Show properly image
    if (
      optionId &&
      $('.js-config-layer[data-id="' + optionId + '"]').length &&
      $this.hasClass('selected')
    ) {
      $('.js-config-layer[data-id="' + optionId + '"]').fadeIn();
    }

    // Pass the title the offer form
    let fullTitle = getFullTitle($container.data('product'), activeOption);
    $(document)
      .find('.js-cabb-variation input')
      .attr('value', fullTitle)
      .trigger('change');

    // Remove spinner
    setTimeout(function () {
      $container.removeClass('ajax-overlay--active');
    }, 500);
  });

  // Configurator buttons
  $(document).on('click', '.js-control-reload', function (e) {
    e.preventDefault();
    let $this = $(this),
      $section = $this.closest('.s-product-configurator'),
      $container = $section.find('.js-config-container'),
      $images = $section.find('.js-config-layer');

    $container.addClass('ajax-overlay--active');

    $(document).find('.js-config-option').removeClass('selected');

    // Hide images
    $images.fadeOut();

    // Remove spinner
    setTimeout(function () {
      $container.removeClass('ajax-overlay--active');
    }, 500);

    // Reset options
    activeOption = {};
    $(document)
      .find('.js-cabb-variation input')
      .attr('value', '')
      .trigger('change');
  });

  // Take a screenshot (photo)
  $(document).on('click', '.js-control-photo', function (e) {
    e.preventDefault();
    let $section = $(this).closest('.s-product-configurator'),
      $container = $section.find('.js-config-container');

    html2canvas($container.get(0)).then((canvas) => {
      canvas.toBlob((blob) => {
        saveAs(blob, $container.data('product') + '-cabb-in.png');
      });
    });
  });
});

function saveAs(blob, fileName = 'photo') {
  const link = document.createElement('a');
  link.download = fileName;
  link.href = URL.createObjectURL(blob);
  link.click();
  URL.revokeObjectURL(link.href);
}

function getFullTitle(title, options) {
  let fullTitle = title;

  if (options) {
    $.each(options, function (key, data) {
      if (data.title) {
        fullTitle += ' / ' + data.title;
      }
    });
  }

  return fullTitle;
}
