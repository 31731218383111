// Import everything from autoload folder
import './autoload/_bootstrap.js'; import './autoload/ada-compliance.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import './plugins/google-map';
import './plugins/configurator';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import { Fancybox } from '@fancyapps/ui';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
import ScrollOut from 'scroll-out';

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  $('.gallery-item')
    .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
    .attr('rel', 'gallery')
    .attr('data-fancybox', 'gallery');

  Fancybox.defaults.autoFocus = false; // prevent focus on form fields on fancybox open
  Fancybox.bind('[data-fancybox]');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Detect element appearance in viewport
   */
  ScrollOut({
    once: true,
  });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /* Scroll next button */
  $(document).on('click', '.js-scroll-next', function (e) {
    e.preventDefault();
    e.stopPropagation();

    let $parent = $(this).closest('section');

    if ($parent.length) {
      $('html, body').animate({ scrollTop: $parent.outerHeight() }, 500);
      return false;
    }
  });

  /**
   * Override bootstrap submenu toggle
   */
  $('.navbar .menu-item-has-children').hover(
    function () {
      if ($(window).width() > 991) {
        $(this).find('.dropdown-menu').addClass('show');
      }
    },
    function () {
      if ($(window).width() > 991) {
        $(this).find('.dropdown-menu').removeClass('show');
      }
    }
  );

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    // jQuery code goes here
  });

  // Anchor click scrolling
  $(document).on('click', 'a[href^="#"]', function (e) {
    if (
      $(this).attr('href') !== '#' &&
      !$(this).hasClass('fancybox') &&
      $(this).attr('role') !== 'tab'
    ) {
      e.preventDefault();
      let $this = $(this);
      if ($($this.attr('href')).length) {
        $('html, body').animate(
          {
            scrollTop: $($this.attr('href')).offset().top - 90 + 'px',
          },
          750,
          'swing'
        );
      }
    }
  });

  // On load *only* if we have anchor on the url
  if (window.location.hash) {
    // smooth scroll to the anchor id
    $('html, body').animate(
      {
        scrollTop: $(window.location.hash).offset().top - 90 + 'px',
      },
      750,
      'swing'
    );
  }

  resizeVideo();
  slidersInit();
}); // END of Document ready

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});

function slidersInit() {
  $(document).find('.js-testimonials-slider:not(.slick-initialized)').slick({
    dots: false,
    arrows: true,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false,
    speed: 1000,
    infinite: false,
    asNavFor: '.js-testimonials-gallery',
    appendArrows: '.js-testimonials-arrows',
  });

  $(document).find('.js-testimonials-gallery:not(.slick-initialized)').slick({
    dots: false,
    arrows: false,
    fade: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    infinite: false,
    speed: 1000,
    asNavFor: '.js-testimonials-slider',
  });

  $(document).find('.js-simple-slider:not(.slick-initialized)').slick({
    dots: false,
    arrows: true,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    infinite: false,
    adaptiveHeight: true,
    speed: 1000,
    appendArrows: '.js-simple-slider-arrows',
  });
}
